.loader {
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #E62B1E;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
}