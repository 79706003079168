header {
  padding: 20px 120px;
  border-bottom: 1px solid var(--border, #D3D3D3);
  display: flex;
  align-items: center;
  justify-content: space-between;

  section {
    display: flex;
    align-items: center;
  }

  .search {
    border-radius: 54px;
    border: 1px solid #D3D3D3;
    background: #FFF;
    padding: 8px 16px; 

    svg {
      padding-right: 6px;
    }

    input {
      width: 228px;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  .ted-logo {
    width: 76px;
    height: 28px;
  }

  span {
    padding-left: 12px;
    font-family: Nunito, 'Courier New', Courier, monospace;
  }
}