h1 {
  font-family: 'Nunito Sans', 'Courier New', Courier, monospace;
  font-weight: 800;
}

p {
  color: rgba(51, 51, 51, 0.80);
}

.videos {
  display: flex;
  flex-wrap: wrap;
  column-gap: 28px;
  row-gap: 28px;
  max-width: 100%;
}

.pagination-container {
  display: flex;
  justify-content: center;
}

.videos-pagination {
  display: inline-flex;
  justify-content: center;
  list-style: none;
  background-color: #FAFAFA;
  padding: 10px 20px;
  margin: 20px 0;
  border-radius: 10px;

  > li {
    padding: 5px 10px;
    color: #22608E;
    font-weight: 600;
    cursor: pointer;
  }

  li.selected {
    border: 1px solid #E5E5E5;
    color: #E62B1E;
    background-color: white;
  }

  li.disabled {
    color: #8a9298;
  }
}
