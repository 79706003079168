.video-container {
  display: flex;
  flex-direction: column;
  width: 380px;

  &-in-focus {
    width: 1200px;
  }

  > section {
    position: relative;
  }

  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}

.video {
  border-radius: 12px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), lightgray 50% / cover no-repeat;
  width: 100%;
  height: 240px;

  &-in-focus {
    height: 640px;
  }
}

.video-card-title {
  color: #19223D;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 12px;
}

.video-card-subtitle {
  color: rgba(51, 51, 51, 0.80);
  font-size: 13px;
  display: flex;
  align-items: center;

  svg {
    padding: 0 5px;
  }
}